$(function () {
    var nextPage = 1;

    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
    });

    const IC_PLAY = `<?xml version="1.0" encoding="UTF-8"?>
    <svg class="bi bi-play-circle" width="25" height="25" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
    </svg>`;

    const IC_PAUSE = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pause-circle" viewBox="0 0 16 16">
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
    <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
  </svg>`;

    function apiAddSongsPlaylists(requestData) {
        requestData.page = nextPage;
        $.ajax({
            url: window.location.href,
            dataType: "json",
            type: "GET",
            data: requestData,
        }).done(function (resp) {
            if (resp?.music) {
                const songs = getAllSongsFromStorage();
                songs.concat(resp.music);
                updateSongsPlaylists(songs);
            }
        });
    }





    function apiUpdatePlaylists(requestData) {
        return new Promise((resolve, reject) => {
            console.log("API request for Playlist songs");
            $.ajax({
                url: window.location.href,
                dataType: "json",
                type: "GET",
                data: requestData,
            })
                .done(function (resp) {
                    if (resp?.music) {
                        updateSongsPlaylists(resp.music);
                        resolve("Songs updated playlists");
                    } else if (requestData?.id) {
                        singleUpdatePlaylistRequest(
                            resolve,
                            reject,
                            requestData
                        );
                    } else {
                        reject(err);
                    }
                })
                .fail(function (err) {
                    if (requestData?.id) {
                        singleUpdatePlaylistRequest(
                            resolve,
                            reject,
                            requestData
                        );
                    } else {
                        reject(err);
                    }
                });
        });
    }

    function singleUpdatePlaylistRequest(resolve, reject, requestData) {
        console.log("API request for Playlist song");
        $.ajax({
            url: app.routes.musicInfo,
            dataType: "json",
            type: "POST",
            data: requestData,
        })
            .done(function (resp) {
                let musics = [];
                musics.push({
                    id: resp.id,
                    title: resp.title,
                    thumb: resp.thumb,
                    url: resp.url,
                    artist_names: resp.artist_names,
                });
                updateSongsPlaylists(musics);
                resolve("Song updated playlist");
            })
            .fail(function (err) {
                reject(err);
            });
    }

    function updateSongData(data) {
        return $.ajax({
            url: app.routes.musicInfo,
            dataType: "json",
            type: "POST",
            data: data,
            success: function (resp) {
                console.log(resp.message);
            },
        });
    }

    function getPlaylistSong(id) {
        const songs = getAllSongsFromStorage();
        return songs.find((item) => item.id == id);
    }

    function getPlaylistSongByIndex(index) {
        const songs = getAllSongsFromStorage();
        if (index >= 0 && index < songs.length) {
            const song = songs[index];
            return song;
        }
        return null;
    }

    function getAllSongsFromStorage() {
        const jsonData = localStorage.getItem("songs");
        const songs = jsonData ? JSON.parse(jsonData) : [];
        return songs.length > 0 ? songs : [];
    }

    function setLastSongPlayed(id) {
        localStorage.setItem("song_playing", id);
    }

    function getLastSongPlayed() {
        return localStorage.getItem("song_playing");
    }

    function getPlaylistSongIndex(id) {
        return getAllSongsFromStorage().findIndex((item) => item.id == id);
    }

    function getQueueSong(queue) {
        if (!currentSong?.id) return null;
        let result = getPlaylistSongIndex(currentSong.id);
        if (result === -1) return null;
        for (let i = 0; i < queue.length; i += 2) {
            const operation = queue[i];
            const number = parseInt(queue[i + 1], 10);
            if (operation === "+") {
                result += number;
            } else if (operation === "-") {
                result -= number;
            }
        }

        const song = getPlaylistSongByIndex(result);
        return song ? song : null;
    }

    /**
     *
     * @param {*} songs
     */
    function updateSongsPlaylists(songs) {
        const jsonData = JSON.stringify(songs);
        localStorage.setItem("songs", jsonData);
    }

    function isPlainObject(variable) {
        return (
            typeof variable === "object" &&
            variable !== null &&
            !Array.isArray(variable)
        );
    }

    var audio = document.createElement("audio");

    audio.addEventListener("ended", function () {
        const song = getQueueSong("+1");
        song ? playSong(song) : console.log("No more Next song");
    });

    audio.addEventListener("loadeddata", function () {
        audio.volume = 1.0;
    });

    var currentSong,
        audioControl = true;

    audio.addEventListener("timeupdate", uiUpdateProgress);



    $("#defaultAudio").on("play", function () {
        console.log("Default audio playing");
        playerPause();
    });



    $("#defaultAudio").on("loadeddata", function () {
        singleMusic();
    });

    async function singleMusic() {
        let id = $(".ring-actions").attr("data-id");
        await apiUpdatePlaylists({ id: id, is_play: true });
        const song = getPlaylistSong(id);
        if (song) {
            currentSong = song;
            setMediaSessions();
        }
        updateSongData({ id: id, views: true });
    }




    function setMediaSessions() {
        if ('mediaSession' in navigator) {
            console.log("Media session updated");
            // Update the media session metadata
            navigator.mediaSession.metadata = new MediaMetadata({
                title: currentSong.title,
                album: currentSong.albumName,
                artist: currentSong.artist_names,
                artwork: currentSong.thumbnails,
            });
        }
    }

    if ('mediaSession' in navigator) {
        // Set up media control actions
        navigator.mediaSession.setActionHandler('play', playerPlay);
        navigator.mediaSession.setActionHandler('pause', playerPause);
        navigator.mediaSession.setActionHandler('nexttrack', playerNext);
        navigator.mediaSession.setActionHandler('previoustrack', playerPrev);
    }



    async function playSong(id) {
        if (!id) {
            stoped();
            return;
        }
        audioControl = false;

        uiProgress();


        if ($("#defaultAudio").length) {
            if ($("#defaultAudio")[0].pause !== true) {
                console.log("Default audio paused");
                $("#defaultAudio")[0].pause();
            }
        }

        if (isPlainObject(id)) {
            currentSong = id;
            uiUpdateProgressList(currentSong.id);
        } else {
            uiUpdateProgressList(id);
            await apiUpdatePlaylists({ id: id, is_play: true });
            currentSong = getPlaylistSong(id);
        }

        if (!currentSong) {
            stoped();
        }

        let playPromise = play();
        playPromise
            .then((_) => {
                audioControl = true;
                setLastSongPlayed(currentSong.id);
                uiUpdateInfo();
                uiUpdatePlay();
                updateSongData({ id: currentSong.id, views: true });
            })
            .catch((error) => {
                console.log(error)
                audioControl = true;
                setLastSongPlayed(currentSong.id);
                console.log("Audio Play failed stoped()");
                stoped();
            });
    }

    async function playFirstSong() {
        uiProgress();
        await apiUpdatePlaylists({ is_play: true });
        songs = getAllSongsFromStorage();
        if (songs.length > 0) {
            playSong(songs[0]);
        } else {
            console.log("Playlist getting empty!");
        }
    }

    function play() {
        audio.setAttribute("src", currentSong.url);
        setMediaSessions();
        audio.currentTime = 0;
        return audio.play();
    }

    function stoped() {
        uiUpdateStoped();
        audio.currentTime = 0;
        audio.setAttribute("src", "");
    }

    function playerPrev() {
        if (!audioControl) {
            console.log("Audio is buffering");
            return false;
        }
        console.log("Playing to previous song");
        const song = getQueueSong("-1");
        song ? playSong(song) : console.log("No more previous song");
    }

    function playerNext() {
        if (!audioControl) {
            console.log("Audio is buffering");
            return false;
        }
        console.log("Playing to next song");
        const song = getQueueSong("+1");
        song ? playSong(song) : console.log("No more next song");
    }

    function playerPlay() {
        if (!audioControl) {
            console.log("Audio is buffering");
            return false;
        }
        if (audio.readyState > 0 && audio.pause) {
            audioControl = false;
            uiProgress();
            uiUpdateProgressList(currentSong.id);
            let playPromise = audio.play();
            playPromise
                .then((_) => {
                    audioControl = true;
                    uiUpdatePlay();
                })
                .catch((error) => {
                    audioControl = true;
                    console.log("Audio Play failed uiUpdateStoped()");
                    uiUpdateStoped();
                });
        } else {
            playFirstSong();
        }
    }

    function playerPause() {
        if (audio.readyState > 0 && audio.paused !== true) {
            audio.pause();
            uiUpdateStoped();
        }
    }


    function uiUpdateInfo() {
        if (currentSong.thumbnails) {
            const smallIcon = currentSong.thumbnails.slice(-1)[0];
            $(".mini-thumb img")
                .removeAttr("srcset")
                .attr("src", smallIcon.src)
                .attr("width", 40)
                .attr("height", 40);
        }
        $(".mini-player").find(".title").html(currentSong.title);
        $(".mini-player").find(".sub-title").html(currentSong.artist_names);
    }

    function uiProgress() {
        uiUpdateStoped();
        $(".mini-thumb img").removeClass("rotate");
        $(".mini-button-group .ic.ic-loader")
            .removeClass("play")
            .removeClass("pause")
            .addClass("spinner-border loading")
            .html("");
    }

    function uiUpdatePlay() {
        $(".mini-thumb img").addClass("rotate");
        $(".mini-button-group .ic.ic-loader")
            .removeClass("spinner-border loading")
            .removeClass("play")
            .addClass("pause");
        $(".mini-button-group .ic.ic-loader").html(IC_PAUSE);
        uiUpdateRoundProgress();
    }

    function uiUpdateStoped() {
        $(".ringtone").removeClass("active");
        $(".mini-thumb img").removeClass("rotate");
        $(".player").css("--rotation", "45deg");
        $(".player").css("--transition", "initial");
        $(".player").html(
            '<svg class="icon icon-play"><use xlink:href="#icon-play"></use></svg>'
        );
        $(".mini-button-group .ic.ic-loader")
            .removeClass("spinner-border loading")
            .removeClass("pause")
            .addClass("play");
        $(".mini-button-group .ic.ic-loader").html(IC_PLAY);
    }

    function uiUpdateProgress() {
        const progressPercent = (audio.currentTime / audio.duration) * 100;
        let progress = $(".miniplayer-progress .progress-bar");
        progress.css("width", `${progressPercent}%`);
        progress.attr("aria-valuenow", progressPercent);
    }


    function uiUpdateProgressList(id) {
        if (!id) return;
        $("div[data-id='" + id + "']")
            .find(".player")
            .html(
                '<svg class="icon spin"><use xlink:href="#icon-spinner"></use></svg>'
            );

        $("div[data-id='" + id + "']").addClass("active");
    }

    function uiUpdateRoundProgress() {
        if (!currentSong?.id) return;
        let duration = audio.duration - audio.currentTime;
        duration = Math.ceil(duration);
        $("div[data-id='" + currentSong.id + "']")
            .find(".player")
            .html(
                '<svg class="icon icon-stop"><use xlink:href="#icon-stop"></use></svg>'
            );
        $("div[data-id='" + currentSong.id + "']")
            .find(".player")
            .css("--rotation", "315deg");
        $("div[data-id='" + currentSong.id + "']")
            .find(".player")
            .css("--transition", "all " + duration + "s");
    }

    $(document).on(
        "click",
        ".ringtone .player,.ringtone-play .player",
        function () {
            if ($(this).find("svg").hasClass("icon-play")) {
                const audioId = $(this).closest(".ringtone").attr("data-id");
                const song = getPlaylistSong(audioId);
                song ? playSong(song) : playSong(audioId);
            } else {
                audio.pause();
                audio.currentTime = 0;
                uiUpdateStoped();
            }
        }
    );


    $(document).on("click", ".mini-button-group .ic.prev", function (e) {
        playerPrev();
    });

    $(document).on("click", ".mini-button-group .ic.next", function (e) {
        playerNext();
    });



    $(document).on("click", ".mini-button-group .ic.play", function (e) {
        playerPlay();
    });

    $(document).on("click", ".mini-button-group .ic.pause", function (e) {
        playerPause();
    });

    $(document).on("click", ".miniplayer-progress", function (e) {
        if (!audio.readyState > 0) {
            return;
        }
        const newTime = e.offsetX / $(this).width();
        const progressPercent = newTime * 100;
        let progress = $(".miniplayer-progress .progress-bar");
        progress.css("width", `${progressPercent}%`);
        progress.attr("aria-valuenow", progressPercent);
        audio.currentTime = newTime * audio.duration;
        uiUpdateRoundProgress();
    });

    $(".miniplayer-progress").on("touchmove", function (e) {
        e.preventDefault();
        if (!audio.readyState > 0) {
            return;
        }
        const touch = e.originalEvent.touches[0];
        const touchX = touch.clientX - $(this).offset().left;
        const progressBarWidth = $(this).width();
        const newTime = touchX / progressBarWidth;
        const percentage = newTime * 100;
        const clampedPercentage = Math.min(Math.max(percentage, 0), 100);
        $(".miniplayer-progress .progress-bar").css("width", clampedPercentage + "%");
        audio.currentTime = newTime * audio.duration;
        uiUpdateRoundProgress();
    });


    $(".downloadSingle").on("click", function (e) {
        e.preventDefault();
        const Id = $(this).val();
        $.ajax({
            url: app.routes.musicInfo,
            dataType: "json",
            type: "POST",
            data: {
                id: Id,
                is_download: true,
            },
        })
            .done((resp) => {
                window.location.replace(resp.url);
            })
            .fail(console.log("Failed to started download"));
    });

    $(document).on("click", ".ringtone .buttons .download", function (e) {
        e.preventDefault();
        let url = $(this).parent().parent().find("a").attr("href");
        window.location.replace(url);
    });

    var sharetitle, shareUrl;

    $(document).on("click", ".ringtone .buttons .share", function (e) {
        e.preventDefault();
        let parent = $(this).parent().parent();
        shareUrl = parent.find("a").attr("href");
        sharetitle = parent.find(".title").text();
        actionShare();
    });

    $(document).on("click", ".ring-actions .share", function (e) {
        e.preventDefault();
        sharetitle = document.title;
        shareUrl = window.location.href;
        actionShare();
    });

    $(document).on("click", ".ringtone .buttons .fav", function (e) {
        e.preventDefault();
        let id = $(this).closest(".ringtone").data("id");
        actionFav(e, id);
    });

    $(document).on("click", ".ring-actions .fav", function (e) {
        e.preventDefault();
        let id = $(this).closest(".ring-actions").data("id");
        actionFav(e, id);
    });

    $(document).on("click", ".ring-actions .edit", function (e) {
        e.preventDefault();
        let id = $(this).closest(".ring-actions").data("id");
        editRingtone(id);
    });

    $(document).on("click", ".ringtone .buttons .edit", function (e) {
        e.preventDefault();
        let id = $(this).closest(".ringtone").data("id");
        editRingtone(id);
    });

    function editRingtone(id) {
        $.ajax({
            url: app.routes.musicInfo,
            type: "post",
            dataType: "json",
            data: {
                id: id,
            },
        })
            .done((res) => {
                $("#ringtone-edit-id").val(res.id);
                $("#ringtone-edit-title").val(res.title);
                $("#ringtone-edit-status").val(res.status);
                $("#ringtone-edit-category").append(new Option(
                    res.album.name,
                    res.album.id,
                    true,
                    true
                )).trigger("change");

                $.each(res.playlists, function (k, v) {
                    $("#ringtone-edit-playlist")
                        .append(new Option(v.name, v.id, true, true))
                        .trigger("change");
                });

                $.each(res.tags, function (k, v) {
                    $("#ringtone-edit-tags")
                        .append(new Option(v.name, v.name, true, true))
                        .trigger("change");
                });

                $.each(res.artists, function (k, v) {
                    $("#ringtone-edit-artists")
                        .append(new Option(v.name, v.name, true, true))
                        .trigger("change");
                });

                if (res?.djremix == true) {
                    $("#isdjremix").prop('checked', true);
                } else {
                    $("#isdjremix").prop('checked', false);
                }

                $("#ringtoneEdit").modal("show");
            })
            .fail(() => {
                console.log("failed to edit song");
            });
    }

    $("#editRingtoneForm").on("submit", function (e) {
        e.preventDefault();
        let editformData = new FormData($(this)[0]);
        $.ajax({
            url: $("#editRingtoneForm").attr("action"),
            type: "POST",
            processData: false,
            contentType: false,
            dataType: "json",
            data: editformData,
        })
            .done((res) => {
                if (res.status == true) {
                    window.location.replace(res.url);
                }
            })
            .fail(() => {
                console.log("failed to edit ringtone");
            });
    });

    $("#playonapp").on("click", function (e) {
        e.preventDefault();

        let deepLinkUrl = app.app_deeplink_custom_host + '?url=' + window.location.href;
        window.location.href = app.app_deeplink_custom_scheme + '://' + deepLinkUrl;

        // Check if the app is installed

        setTimeout(function () {
            let startTime = Date.now();
            window.location.href = 'intent://' + deepLinkUrl + '#Intent;scheme=' + app.app_deeplink_custom_scheme +
                ';package=' + app.app_package_name + ';end';
            let endTime = Date.now();
            if (endTime - startTime < 1000) {
                window.location.href = 'market://details?id=' + app.app_package_name;
            }
        }, 3000);
    });

    $("#clipcopy").on("click", function (e) {
        e.preventDefault();
        if (navigator.clipboard !== undefined) {
            let copyText = $("#share-url");
            copyText.select();
            navigator.clipboard.writeText(copyText.val());
        } else {
            console.log("Clipboard not suppored");
        }
    });

    function actionFav(e, fid) {
        elem = $(e.currentTarget);
        $.ajax({
            url: window.app.routes.musicInfo,
            type: "post",
            dataType: "json",
            data: {
                id: fid,
                favourite: true,
            },
        })
            .done((resp) => {
                console.log(resp.message);
                elem.toggleClass("light");
            })
            .fail(() => {
                console.log("Favourite faild :id " + fid);
            });
    }

    function actionShare() {
        if (navigator.share !== undefined) {
            //Web share API
            navigator
                .share({
                    title: "Share",
                    text: sharetitle,
                    url: shareUrl,
                })
                .then(function () {
                    console.info("Shared successfully.");
                })
                .catch(function (error) {
                    console.error("Error in sharing: ", error);
                });
        } else {
            $("#share-url").val(shareUrl);
            $("#modalDialog").modal("show");
        }
    }
    $(".shares").on("click", "button,a", function (e) {
        e.preventDefault();
        let OUrl = $(this)
            .data("href")
            .replace("(title)", sharetitle)
            .replace("(url)", shareUrl);
        window.open(OUrl);
    });

    $("#loader").on("click", function (e) {
        e.preventDefault();
        LoadData();
    });

    function LoadData() {
        ApiPaging(function (body) {
            $("#" + $("#pagination").data("id")).append(body);
            apiAddSongsPlaylists({ is_play: true });
        });
    }

    //Api Pagging
    var isLastPage = false;
    var isLoading = false;
    var loaderStyle = $("#loader").attr("data-type");
    function showloading(status) {
        if (status == true) {
            if (loaderStyle == "scroll") {
                isLoading = true;
                $("#loader").show();
            } else {
                $("#loader").prepend(
                    '<span id="loading" class="spinner-grow spinner-grow-sm" aria-hidden="true"></span> '
                );
                $("#loader").attr("disabled", "disabled");
            }
        } else {
            if (loaderStyle == "scroll") {
                isLoading = false;
                $("#loader").hide();
            } else {
                $("#loader #loading").remove();
                $("#loader").removeAttr("disabled");
            }
        }
    }

    function ApiPaging(callback) {
        nextPage = parseInt($("#pagination").attr("data-page")) + 1;
        let datahref = $("#pagination").attr("data-href");
        let reqUrl = datahref ? datahref : window.location.toString();
        let totalPage = parseInt($("#pagination").attr("data-pages"));
        $.ajax({
            url: reqUrl,
            type: "GET",
            dataType: "json",
            data: {
                page: nextPage,
            },
            beforeSend: showloading(true),
        })
            .done(function (resp) {
                showloading(false);
                if (nextPage >= totalPage) {
                    isLastPage = true;
                    $("#loader").remove();
                }
                $("#pagination").attr("data-page", nextPage);
                if (resp.status == true) {
                    startPagination();
                    callback(resp.data);
                }
            })
            .fail(function () {
                $("#loader").remove();
                console.log("API Fetch Error" + reqUrl);
            });
    }

    /**
     * if Pagination and
     *  window and document width same
     */
    function startPagination() {
        if ($(document).height() == $(window).height()) {
            if (loaderStyle == "scroll") {
                if (!isLoading && !isLastPage) {
                    setTimeout(() => {
                        LoadData();
                    }, 400);
                }
            }
        }
    }

    /**
     * on scroll event
     */
    $(window).on("scroll", function () {
        if (loaderStyle == "scroll") {
            if (
                $(window).scrollTop() >=
                $(document).height() - $(window).height() - 100
            ) {
                if (!isLoading && !isLastPage) {
                    LoadData();
                }
            }
        }
    });

    /**
     *  Start Select 2
     */



    var dropDownParent;

    if ($("#ringtoneUpload").length > 0) {
        dropDownParent = $("#ringtoneUpload");
    } else {
        dropDownParent = $("#ringtoneEdit");
    }

    if ($(".uploads-catlist").length) {
        $(".uploads-catlist").select2({
            dropdownParent: dropDownParent,
            placeholder: "Select Album",
            theme: "bootstrap-5",
            ajax: {
                url: $(".uploads-catlist").data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
        });
    }

    if ($(".playlistAjax").length) {
        $(".playlistAjax").select2({
            placeholder: "Select Playlist",
            dropdownParent: dropDownParent,
            theme: "bootstrap-5",
            ajax: {
                url: $(".playlistAjax").data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
        });
    }

    if ($(".artistsAjax").length) {
        $(".artistsAjax").select2({
            ajax: {
                url: $(".artistsAjax").data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
            dropdownParent: dropDownParent,
            theme: "bootstrap-5",
            tags: true,
            placeholder: "Select Artist or create (,)",
            tokenSeparators: [",", ";", "\n"],
            createTag: function (params) {
                var term = $.trim(params.term);
                if (term === "") {
                    return null;
                }

                return {
                    id: term,
                    text: term,
                    newTag: true,
                };
            },
        });
    }

    if ($(".tagsinput-upload").length) {
        $(".tagsinput-upload").select2({
            ajax: {
                url: $(".tagsinput-upload").data("href"),
                dataType: "json",
                data: function (params) {
                    var query = {
                        q: params.term,
                    };
                    return query;
                },
            },
            dropdownParent: dropDownParent,
            theme: "bootstrap-5",
            tags: true,
            placeholder: "Enter Tags then hit (,)",
            tokenSeparators: [",", ";", "\n"],
            createTag: function (params) {
                var term = $.trim(params.term);
                if (term === "") {
                    return null;
                }

                return {
                    id: term,
                    text: term,
                    newTag: true,
                };
            },
        });
    }
    /**End Select 2 */

    /**
     * if pagination avaiable and document width and window width same;
     */
    startPagination();
});
